<template>
	<div class="bg-index" :style="{backgroundImage: 'url(' + bg + ')'}">


		<div class="buying-main">
			<div class="zhanwei"></div>
			<div v-if="loginStatus">
				<div v-if="xieyi">
					<div class="buying-main-title">
						<div>在线寄件：</div>
						<div class="buying-main-type">
							<div @click="avatarClick(1)" :class="[fomr.wl_type == 1?'buying-pubilc-avatar':'']"
								class="buying-pubilc ">海运</div>
							<div @click="avatarClick(2)" :class="[fomr.wl_type == 1?'':'buying-pubilc-avatar']"
								class="buying-pubilc">空运</div>
						</div>
					</div>

					<div class="buying-all-public">
						<div class="buying-title">
							<el-image class="buying-main-image" :src="require('@/assets/image/ji.png')"></el-image>
							<div>寄件人</div>
							<div class="buy-biexuan">
								<el-radio v-model="radio" label="1">私人物品</el-radio>
								<el-radio v-model="radio" label="2">电商</el-radio>
							</div>
						</div>
						<div v-show="radio == 1" class="buying-form">
							<el-row :gutter="80">
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>姓名：</div>
										<input v-model="fomr.jj_name" placeholder="请填写联系人姓名" />
									</div>
								</el-col>
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>手机号码：</div>
										<input v-model="fomr.jj_phone" placeholder="请填写联系人手机号码" />
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="80">
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>城市/区域：</div>
										<input v-model="fomr.jj_qu" placeholder="请填写城市/区域" />
									</div>
								</el-col>
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>省/州/郡：</div>
										<input v-model="fomr.ji_sheng" placeholder="请填写省/州/郡" />
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="80">
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>邮编：</div>
										<input v-model="fomr.ji_yb" placeholder="请填写邮编" />
									</div>
								</el-col>
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>国家：</div>
										<input v-model="fomr.jj_guo" placeholder="请填写国家" />
									</div>
								</el-col>
							</el-row>
							<!-- <div class="input-all">
                            <div class="input-xing">*</div>
                            <div >地址：</div>
                            <input class="address-main-all" v-model="fomr.jj_dz"  placeholder="请填写联系人地址"/>
                        </div> -->
						</div>

						<div v-show="radio == 2" class="buying-form">
							<el-row :gutter="80">
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>姓名：</div>
										<input v-model="fomr.jj_name" placeholder="请填写联系人姓名" />
									</div>
								</el-col>
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>手机号码：</div>
										<input v-model="fomr.jj_phone" placeholder="请填写联系人手机号码" />
									</div>
								</el-col>
							</el-row>
							<div class="input-all">
								<div class="input-xing">*</div>
								<div>单号：</div>
								<input class="address-main-all" v-model="fomr.shop_number" placeholder="请填写单号" />
							</div>
						</div>
					</div>


					<div class="buying-all-public">
						<div class="buying-title ">
							<el-image class="buying-main-image" :src="require('@/assets/image/zhong.png')"></el-image>
							<div>国内中转仓库地址</div>
						</div>
						<div class="buying-form">
							<el-row :gutter="80">
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>姓名：</div>
										<input readonly :value="fomr.zz_name" placeholder="请填写联系人姓名" />
									</div>
								</el-col>
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>手机号码：</div>
										<input readonly :value="fomr.zz_phone" placeholder="请填写联系人手机号码" />
									</div>
								</el-col>
							</el-row>
							<div class="input-all">
								<div class="input-xing">*</div>
								<div style="width:80px">地址：</div>
								<div class="address-main-all">{{fomr.zz_dz}}</div>
								<!-- <input  class="address-main-all" readonly :value="fomr.zz_dz"   placeholder="请填写联系人地址"/> -->
							</div>
						</div>

						<div class="buying-titles">
							<div style="display: flex;align-items: center;">
								<div class="buying-title">
									<el-image class="buying-main-image" :src="require('@/assets/image/shou2.png')">
									</el-image>
									<div>收件人</div>
								</div>
								<div class="buy-biexuan">
									<el-radio v-model="radios" label="1">派送</el-radio>
									<el-radio v-model="radios" label="2">自提</el-radio>
								</div>
							</div>

							<div style="visibility: hidden;" @click="SelectAddress" class="buying-select">
								<el-image class="buying-select-image" :src="require('@/assets/image/address.png')">
								</el-image>
								<div>选择收货地址</div>
							</div>

						</div>
						<div v-show="radios == 1" class="buying-form">
							<el-row :gutter="80">
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>姓名：</div>
										<input v-model="fomr.sj_name" placeholder="请填写联系人姓名" />
									</div>
								</el-col>
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>手机号码：</div>
										<input v-model="fomr.sj_phone" placeholder="请填写联系人手机号码" />
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="80">
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>城市/区域：</div>
										<input v-model="fomr.sj_qu" placeholder="请填写城市/区域" />
									</div>
								</el-col>
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>省/州/郡：</div>
										<input v-model="fomr.shou_sheng" placeholder="请填写省/州/郡" />
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="80">
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>邮编：</div>
										<input v-model="fomr.shou_yb" placeholder="请填写邮编" />
									</div>
								</el-col>
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>国家：</div>
										<input v-model="fomr.sj_guo" placeholder="请填写国家" />
									</div>
								</el-col>
							</el-row>
							<div class="input-all">
								<div class="input-xing">*</div>
								<div>地址：</div>
								<input class="address-main-all" v-model="fomr.sj_dz" placeholder="请填写联系人地址" />
							</div>
						</div>
						<div v-show="radios == 2" class="buying-form">
							<div class="input-all">
								<div class="input-xing">*</div>
								<div>地址：</div>
								<input class="address-main-all" value="这是一个自提点" readonly placeholder="请填写联系人地址" />
							</div>
						</div>
					</div>


					<div class="buying-all-public">
						<div class="buying-title">
							<div>货物信息</div>
						</div>
						<!-- <div class="buying-form">
                        <div class="buying-title bottom-form">
                            <div class="input-xing">*</div>
                            <div class="input-danhao">物品类型</div>
                        </div>
                        <div class="select-all">
                            <div @click="statusClick(key)" :class="[item.status?'status-all':'']" v-for="(item,key) in typeList" :key="key">{{item.name}}</div>
                        </div>
                    </div> -->
						<div class="buying-form">
							<el-row :gutter="80">
								<!-- <el-col :span="12">
                                <div class="input-all">
                                    <div class="input-xing">*</div>
                                    <div>约重量：</div>
                                    <input v-model="fomr.yg_zl"  placeholder="请输入物品约重量（KG）"/>
                                </div>
                            </el-col> -->
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>货物品名：</div>
										<input v-model="fomr.goods_name" placeholder="请输入货物品名" />
									</div>
								</el-col>
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>数量：</div>
										<input v-model="fomr.number" placeholder="请输入数量" />
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="80">
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>材质：</div>
										<input v-model="fomr.material" placeholder="请输入材质" />
									</div>
								</el-col>
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>货值：</div>
										<input v-model="fomr.goods_value" placeholder="请输入货值" />
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="80">
								<el-col :span="12">
									<div class="input-all">
										<div class="input-xing">*</div>
										<div>快递单号：</div>
										<input v-model="fomr.kd_order" placeholder="请输入快递单号" />
									</div>
								</el-col>
							</el-row>
						</div>
						<el-button class="btn" @click="addorder">添加包裹</el-button>
					</div>
					<!-- 暂存货物信息 -->
					<div class="from_title">我的商品信息列表</div>
					<el-table :data="tableData" :row-key="kd_order" style="width: 100%" border>
						<el-table-column prop="goods_name" label="货物品名" width="180" align="center">
						</el-table-column>
						<el-table-column prop="number" label="数量" width="180" align="center">
						</el-table-column>
						<el-table-column prop="material" label="内容" align="center">
						</el-table-column>
						<el-table-column prop="goods_value" label="货值" align="center">
						</el-table-column>
						<el-table-column prop="kd_order" label="快递单号" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button class="btn2" @click="deleteRow(scope.$index, tableData)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>

					<div class="xieyi-all">
						<el-checkbox v-model="checked" style="margin-right: 10px;"></el-checkbox>
						请您仔细认真阅读
						<span class="xieyi" @click="xieyiClick('haiyun')">《海运协议》</span> 和
						<span class="xieyi" @click="xieyiClick('kongyun')">《空运协议》</span>
					</div>
					<el-button :loading="buttonStatus" @click="submitClick" class="sumbit-button">提交订单</el-button>
				</div>
				<div v-else>
					<el-card>
						<div v-html="contetn"></div>
					</el-card>
					<div class="button-all">
						<el-button class="buitton-back" @click="backClick" type="primary">返回</el-button>
					</div>
				</div>
			</div>
			<div v-else style="text-align: center;">
				<div class="istoken">
					<el-image class="istoken-image" :src="require('@/assets/image/gonggao.png')"></el-image>
				</div>
			</div>

			<el-dialog title="选择收件地址" :visible.sync="dialogVisible" width="30%">
				<div style="height:600px;">
					<el-scrollbar class="dialog-main">
						<div v-for="(item,key) in AddressList" :key="key" class="dialog-all">
							<el-image class="buying-main-image" :src="require('@/assets/image/address1.png')">
							</el-image>
							<div class="address-main-all">
								<div class="dialog-title">
									<div class="buying-title">
										<div class="dialog-name">{{item.name}}</div>
										<div class="dialog-phone">{{item.mobile}}</div>
									</div>
									<div @click="selectClick(item)" class="dialog-button">选择</div>
								</div>
								<div>{{item.sheng}}{{item.shi}}{{item.qu}}{{item.address}}</div>
							</div>
						</div>
					</el-scrollbar>
				</div>
			</el-dialog>

		</div>

	</div>
</template>

<script>
	import {
		showaddress,
		settings,
		addorder,
		getset
	} from '@/api/index'
	export default {
		name: '',
		data() {
			return {
				fomr: {
					// jj 寄件人
					jj_name: '', // 姓名
					jj_phone: '', // 手机
					jj_dz: '', // 地址
					ji_sheng: '', // 省
					jj_qu: '', // 区
					ji_yb: '', // 邮编
					jj_guo: '', //国家
					// 中转
					zz_phone: '',
					zz_name: '',
					zz_dz: '',
					// 收件人
					sj_name: '',
					sj_phone: '',
					sj_dz: '',
					shou_sheng: '', // 省
					sj_qu: '', // 区
					shou_yb: '', // 邮编
					sj_guo: '', //国家

					wl_type: 1, //物流方式:1海运,2空运
					wp_type: '', // 物品类型
					yg_zl: '', //预估重量,非必填
					kd_order: '', //快递单号
					shop_number: '', //电商单号

					goods_name: '', //货物品名
					number: '', //数量
					material: '', //材质
					goods_value: '' //货值
				},
				radio: '1',
				radios: '1',
				typeList: [{
					id: 0,
					name: '食品',
					status: false,
				}, {
					id: 1,
					name: '服装',
					status: false,
				}, {
					id: 2,
					name: '生活用品',
					status: false,
				}, {
					id: 3,
					name: '数码用品',
					status: false,
				}, {
					id: 4,
					name: '文件',
					status: false,
				}, {
					id: 5,
					name: '其他',
					status: false,
				}], // 物品类型选择
				buttonStatus: false, // button状态
				dialogVisible: false, // 地址选择打开框
				AddressList: [], // 地址列表
				checked: false,
				kongyunAddress: '', // 空运地址
				haiyunAddress: '', // 海运地址
				xieyi: true,
				contetn: '',
				bg: '',
				top: '', // 当前页面高度
				loginStatus: false,
				tableData: [] //暂存货物信息
			}
		},
		created() {
			getset({
				name: 'buyong_bg'
			}).then(res => {
				this.bg = this.$store.state.baseUrl + res.data
			})
			let token = JSON.parse(sessionStorage.getItem('token'))

			if (token) {
				this.getAddressList()
				this.getsettings(1)
				this.getsettings(2)
				this.loginStatus = true
			} else {
				this.loginStatus = false
			}

		},
		methods: {
			addorder() {
				if (this.fomr.goods_name == '' || this.fomr.number == '' || this.fomr.material == '' || this.fomr
					.goods_value == '' || this.fomr.kd_order == '') {
					this.$message({
						type: 'warning',
						message: '货物信息不完整'
					})
					return
				}
				let data = {
					goods_name: this.fomr.goods_name,
					number: this.fomr.number,
					material: this.fomr.material,
					goods_value: this.fomr.goods_value,
					kd_order: this.fomr.kd_order,
				}
				this.tableData = [...this.tableData, data]
				this.fomr.goods_name = ''
				this.fomr.number = ''
				this.fomr.material = ''
				this.fomr.goods_value = ''
				this.fomr.kd_order = ''
				console.log(this.tableData)

			},
			deleteRow(index, rows) {
				rows.splice(index, 1);
			},
			blurPrice() {
				if ((/^[0-9]*$ /.test(this.fomr.yg_zl))) {
					this.$message({
						type: 'warning',
						message: '必须输入数字'
					})
					this.fomr.yg_zl = ''
					return
				}

			},
			// 协议
			xieyiClick(name) {
				let top = document.documentElement.scrollTop || document.body.scrollTop;
				this.top = top
				console.log(top)
				// 实现滚动效果 
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
					if (top <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
				getset({
					name
				}).then(res => {
					this.contetn = res.data
					this.xieyi = false
				})
			},
			backClick() {
				this.xieyi = true
				let top = 0
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top += 50;
					if (top >= this.top) {
						clearInterval(timeTop);
					}
				}, 10);
			},
			//提交订单
			submitClick() {
				if (!this.checked) {
					this.$message({
						type: 'warning',
						message: '请阅读并勾选协议'
					})
					return
				}
				let jj_dz = this.fomr.jj_guo + this.fomr.ji_sheng + this.fomr.jj_qu + this.fomr.jj_dz
				let sj_dz = this.fomr.sj_guo + this.fomr.shou_sheng + this.fomr.sj_qu + this.fomr.sj_dz
				let goodsinfo = JSON.stringify(this.tableData)
				let data = JSON.stringify(this.fomr)
				data = JSON.parse(data)
				data.goods = JSON.parse(goodsinfo)
				data.jj_dz = jj_dz
				data.sj_dz = sj_dz
				data.send_type = this.radio
				data.receive_type = this.radios
				this.buttonStatus = true
				addorder(data).then(res => {
					this.buttonStatus = false
					if (res.code) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.fomr = {}
						this.tableData = []
					}
				})
			},
			// 选择收货地址
			selectClick(row) {
				this.fomr.sj_name = row.name
				this.fomr.sj_phone = row.mobile
				this.fomr.sj_dz = row.address
				this.fomr.shou_sheng = row.sheng
				this.fomr.sj_qu = row.shi
				this.fomr.shou_yb = row.youbian
				this.fomr.sj_guo = row.guojia
				this.dialogVisible = false
			},
			// 获取国内中转仓库地址
			getsettings(type) {
				settings({
					type
				}).then(res => {
					// type  1海  2空
					if (type == 1) {
						this.haiyunAddress = res.data
						this.fomr.zz_dz = res.data.address
						this.fomr.zz_phone = res.data.phone
						this.fomr.zz_name = res.data.shr
					} else {
						this.kongyunAddress = res.data
					}

				})
			},
			// 获取收货地址
			getAddressList() {
				showaddress().then(res => {
					this.AddressList = res.data
				})
			},
			// 切换空运还是 海运
			avatarClick(id) {
				this.fomr.wl_type = id
				if (id == 1) {
					this.fomr.zz_dz = this.haiyunAddress.address
					this.fomr.zz_phone = this.haiyunAddress.phone
					this.fomr.zz_name = this.haiyunAddress.shr
				} else {
					this.fomr.zz_dz = this.kongyunAddress.address
					this.fomr.zz_phone = this.kongyunAddress.phone
					this.fomr.zz_name = this.kongyunAddress.shr
				}

			},

			// 选择物品信息
			statusClick(key) {
				this.typeList.forEach((item, key) => {
					item.status = false
				})
				this.fomr.wp_type = this.typeList[key].name
				this.typeList[key].status = true
			},
			// 选择收货地址
			SelectAddress() {
				let token = JSON.parse(sessionStorage.getItem('token'))
				if (token) {
					this.dialogVisible = true
				} else {
					this.$message({
						type: 'warning',
						message: '请登录后操作'
					})
				}

			},
			load() {}
		},
	}
</script>

<style>
	/* .el-scrollbar{height:100%;} */
	.el-scrollbar__wrap {
		overflow-x: hidden;
		overflow: hidden;
	}

	.el-radio {
		color: #ffffff
	}

	.el-radio__label {
		font-size: 30px;
	}
</style>

<style scoped>
	::-webkit-input-placeholder {
		color: #BBBBBB;
	}

	::-moz-placeholder {
		color: #BBBBBB;
	}

	/* firefox 19+ */
	:-ms-input-placeholder {
		color: #BBBBBB;
	}

	/* ie */
	input:-moz-placeholder {
		color: #BBBBBB;
	}

	input:focus {
		outline: medium;
	}

	.buy-biexuan {
		margin-left: 100px;
	}

	.bg-index {
		background-attachment: fixed;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}

	input {
		background: transparent;
		color: #ffffff;
		border: none;
		font-size: 28px;
	}

	.zhanwei {
		height: 120px;
	}

	.buying-main {
		width: 70%;
		margin: auto;
		padding-top: 38px;
		/* background: #F5F5F5; */
	}

	.buying-main-title {
		height: 112px;
		background: #F6AF16;
		padding: 0 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #ffffff;
		font-size: 28px;
	}

	.buying-main-type {
		display: flex;
		align-items: center;
	}

	.buying-pubilc {
		width: 145px;
		height: 47px;
		line-height: 47px;
		text-align: center;
		cursor: pointer;
	}

	.buying-pubilc-avatar {
		background: #FFFFFF;
		border-radius: 24px;
		font-size: 28px;
		font-weight: bold;
		color: #C22900;
	}

	.buying-main-image {
		width: 54px;
		height: 54px;
		margin-right: 30px;
	}

	.buying-all-public {
		/* background: #ffffff; */
		margin-top: 20px;
		padding: 48px 30px;
		font-size: 28px;
		/* color: #333333; */
		color: #ffffff;
		border-radius: 4px;
	}

	.buying-title {
		display: flex;
		align-items: center;
	}

	.input-xing {
		color: #C43410;
		width: 20px;
	}

	.input-all {
		display: flex;
		align-items: center;
		font-size: 28px;
		color: #BBBBBB;
		padding: 40px 0 26px 0;
		border-bottom: 2px solid #F5F5F5;
		;
	}

	.buying-form {
		margin: 0 50px;
	}

	.buying-titles {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 52px;
	}

	.buying-select {
		width: 239px;
		height: 57px;
		background: #506EDA;
		border-radius: 29px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		cursor: pointer;
		color: #FFFFFF;
	}

	.buying-select-image {
		width: 34px;
		height: 34px;
		margin-right: 20px;
	}

	.input-danhao {
		color: #BBBBBB;
	}

	.select-all {
		margin-top: 30px;
		display: flex;
		align-items: center;
		flex-wrap: wrap
	}

	.select-all div {
		height: 50px;
		line-height: 50px;
		/* background: #F5F5F5; */
		border-radius: 3px;
		margin-right: 20px;
		margin-bottom: 20px;
		font-size: 24px;
		font-weight: 500;
		/* color: #333333; */
		color: #ffffff;
		padding: 0 40px;
		cursor: pointer;
	}

	.bottom-form {
		margin-top: 45px;
	}

	.sumbit-button {
		width: 100%;
		height: 126px;
		background: #F6AF16;
		border-radius: 10px;
		font-size: 32px;
		font-weight: bold;
		color: #FFFFFF;
		border: none;
		margin: 48px 0 62px 0;
	}

	.dialog-all {
		display: flex;
		align-items: center;
		padding: 0 27px;
		width: 706px;
		height: 185px;
		background: #F5F5F5;
		border-radius: 10px;
		margin-bottom: 20px;
		font-size: 20px;
		font-weight: 500;
		color: #333333;
	}

	.dialog-main {
		display: flex;
		align-items: center;
		flex-direction: column;
		height: 100%;
	}

	.dialog-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 23px;
	}

	.dialog-button {
		width: 66px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		border: 1px solid #FF521E;
		border-radius: 8px;
		font-size: 18px;
		color: #FF521E;
		cursor: pointer;
	}

	.dialog-name {
		font-size: 29px;
		font-weight: 500;
		color: #333333;
		margin-right: 23px;
	}

	.dialog-phone {
		font-size: 25px;
		font-weight: 500;
		color: #333333;
	}

	.status-all {
		background: #f7b832 !important;
		color: #ffffff !important;
	}

	.address-main-all {
		min-width: 80%;
	}

	.input-width {
		width: 500px;
	}

	.xieyi {
		color: #409EFF;
		text-decoration: none;
		cursor: pointer;
	}

	.xieyi-all {
		margin-top: 30px;
		color: #ffffff;
		font-size: 26px;
		display: flex;
		align-items: center;
	}

	.button-all {
		text-align: center;
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.buitton-back {
		width: 400px;
		font-size: 30px;
	}

	.istoken {
		min-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.istoken-image {
		width: 850px;
		margin-bottom: 250px;
	}

	.btn {
		width: 200px;
		height: 60px;
		background: #f7b832;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 30px;
		border: 0;
	}

	.btn2 {
		width: 120px;
		height: 40px;
		background: #f7b832;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0;
		margin: 0 auto;
	}

	.from_title {
		font-size: 32px;
		color: #fff;
		margin-bottom: 30px;
	}
</style>
